import React from "react";
import {Box, Grid} from "@material-ui/core";
import {FrontGridOne} from "../../shared/components/templates/front/FrontGridOne";
import {FrontSectionDataType} from "../../shared/types";
import {FrontGridTwo} from "../../shared/components/templates/front/FrontGridTwo";
import {FrontGridThree} from "../../shared/components/templates/front/FrontGridThree";
import {FrontGridFour} from "../../shared/components/templates/front/FrontGridFour";
import {FrontGridFive} from "../../shared/components/templates/front/FrontGridFive";
import {FrontGridSix} from "../../shared/components/templates/front/FrontGridSix";
import {FrontGridSeven} from "../../shared/components/templates/front/FrontGridSeven";
import {FrontGridEight} from "../../shared/components/templates/front/FrontGridEight";
import {FrontGridNine} from "../../shared/components/templates/front/FrontGridNine";
import {FrontBoxItem} from "../../shared/components/templates/front/components/FrontBoxItem";
import {onClickPost} from "../../shared/utils";
import {FrontGridTen} from "../../shared/components/templates/front/FrontGridTen";
import {FrontGridEleven} from "../../shared/components/templates/front/FrontGridEleven";

export const SectionView: React.FC<FrontSectionDataType> = ({
  data
}) => {

  if (data.note) {
    return <Box>
      <Grid container spacing={3} style={{height: '100%'}}>
        {
          data.postItemsSelected.map((item, key) => {
            return (
              <Grid item md={12}>
                <FrontBoxItem
                  item={item}
                  paddingPaper={false}
                  showDescription={false}
                  showAuthor={false}
                  showCategory={false}
                  centerTitle
                  horizontal
                  onClick={() => onClickPost(item.postLink)}
                  itemName={`${item}-${key}`}
                  sectionId={data.sectionId}
                  href={item.postLink}
                />
              </Grid>
            );
          })
        }
      </Grid>
    </Box>;
  }



  return (
    <Box p={2} width='100%'>
      <Grid container spacing={3} style={{height: '100%'}}>
        <Grid item md={12}>
          {
            data.selectedGrid === 'grid-one' ? (
              <FrontGridOne items={data.postItemsSelected} sectionId={data.sectionId} />
            ) : null
          }
          {
            data.selectedGrid === 'grid-two' ? (
              (<FrontGridTwo items={data.postItemsSelected} sectionId={data.sectionId}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-three' ? (
              (<FrontGridThree items={data.postItemsSelected} sectionId={data.sectionId}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-four' ? (
              (<FrontGridFour items={data.postItemsSelected} sectionId={data.sectionId}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-five' ? (
              (<FrontGridFive items={data.postItemsSelected} sectionId={data.sectionId}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-six' ? (
              (<FrontGridSix items={data.postItemsSelected} sectionId={data.sectionId}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-seven' ? (
              (<FrontGridSeven items={data.postItemsSelected} sectionId={data.sectionId} />)
            ) : null
          }
          {
            data.selectedGrid === 'grid-eight' ? (
              (<FrontGridEight items={data.postItemsSelected} sectionId={data.sectionId} />)
            ) : null
          }
          {
            data.selectedGrid === 'grid-nine' ? (
              (<FrontGridNine items={data.postItemsSelected} sectionId={data.sectionId} random={data.random}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-ten' ? (
              (<FrontGridTen items={data.postItemsSelected} sectionId={data.sectionId} random={data.random}/>)
            ) : null
          }
          {
            data.selectedGrid === 'grid-eleven' ? (
              (<FrontGridEleven items={data.postItemsSelected} sectionId={data.sectionId} random={data.random}/>)
            ) : null
          }
        </Grid>
      </Grid>
    </Box>
  );
};