import React from "react";
import {Grid} from "@material-ui/core";
import {FrontGridType} from "../../../types";
import {FrontContainerBox} from "./components/FrontContainerBox";
import {FrontBoxItem} from "./components/FrontBoxItem";
import {onClickPost} from "../../../utils";

export const FrontGridSeven: React.FC<FrontGridType> = ({
  items, sectionId
}) => {

  const item1 = items.find((item)=>{return item.position === 1;});
  const item2 = items.find((item)=>{return item.position === 2;});
  const item3 = items.find((item)=>{return item.position === 3;});

  return (
    <Grid container  spacing={1} >
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item1 ?(
              <FrontBoxItem
                item={item1}
                paddingPaper={false}
                showDescription={false}
                showAuthor={false}
                showCategory={false}
                centerTitle
                horizontal
                onClick={()=>onClickPost(item1.postLink)}
                itemName='item1'
                sectionId={sectionId}
                href={item1.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item2 ?(
              <FrontBoxItem
                item={item2}
                paddingPaper={false}
                showDescription={false}
                showAuthor={false}
                showCategory={false}
                centerTitle
                horizontal
                onClick={()=>onClickPost(item2.postLink)}
                itemName='item2'
                sectionId={sectionId}
                href={item2.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item3 ?(
              <FrontBoxItem
                item={item3}
                paddingPaper={false}
                showDescription={false}
                showAuthor={false}
                showCategory={false}
                centerTitle
                horizontal
                onClick={()=>onClickPost(item3.postLink)}
                itemName='item3'
                sectionId={sectionId}
                href={item3.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>

    </Grid>
  );
};