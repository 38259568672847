import React from "react";
import {Grid, useMediaQuery, Box} from "@material-ui/core";
import {FrontGridType} from "../../../types";
import {FrontContainerBox} from "./components/FrontContainerBox";
import {FrontBoxItem} from "./components/FrontBoxItem";
import {onClickPost} from "../../../utils";
import {useTheme} from "@material-ui/core/styles";

export const FrontGridEight: React.FC<FrontGridType> = ({
  items, sectionId
}) => {



  const item1 = items.find((item)=>{return item.position === 1;});
  const item2 = items.find((item)=>{return item.position === 2;});
  const item3 = items.find((item)=>{return item.position === 3;});
  const item4 = items.find((item)=>{return item.position === 4;});

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  if(isSmall){
    return (
      <>
        <Box width='100%'>
          <FrontContainerBox>
            {
              item1 ?(
                <FrontBoxItem
                  item={item1}
                  paddingPaper={false}
                  centerTitle
                  onClick={()=>onClickPost(item1.postLink)}
                  itemName='item1'
                  sectionId={sectionId}
                  href={item1.postLink}


                />
              ):null
            }
          </FrontContainerBox>
        </Box>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <FrontContainerBox>
              {
                item2 ?(
                  <FrontBoxItem
                    item={item2}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item2.postLink)}
                    itemName='item2'
                    sectionId={sectionId}
                    href={item2.postLink}
                    horizontal
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item md={12}>
            <FrontContainerBox>
              {
                item3 ?(
                  <FrontBoxItem
                    item={item3}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item3.postLink)}
                    itemName='item3'
                    sectionId={sectionId}
                    href={item3.postLink}
                    horizontal
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item md={12}>
            <FrontContainerBox>
              {
                item4 ?(
                  <FrontBoxItem
                    item={item4}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item4.postLink)}
                    itemName='item4'
                    sectionId={sectionId}
                    href={item4.postLink}
                    horizontal
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
        </Grid>
      </>
    );
  }


  return (
    <Grid container  spacing={1} >
      <Grid item md={8}>
        <FrontContainerBox>
          {
            item1 ?(
              <FrontBoxItem
                item={item1}
                paddingPaper={false}
                centerTitle
                onClick={()=>onClickPost(item1.postLink)}
                itemName='item1'
                sectionId={sectionId}
                href={item1.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={4}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <FrontContainerBox>
              {
                item2 ?(
                  <FrontBoxItem
                    item={item2}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item2.postLink)}
                    itemName='item2'
                    sectionId={sectionId}
                    href={item2.postLink}

                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item md={6}>
            <FrontContainerBox>
              {
                item3 ?(
                  <FrontBoxItem
                    item={item3}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item3.postLink)}
                    itemName='item3'
                    sectionId={sectionId}
                    href={item3.postLink}
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item md={6}>
            <FrontContainerBox>
              {
                item4 ?(
                  <FrontBoxItem
                    item={item4}
                    paddingPaper={false}
                    centerTitle
                    onClick={()=>onClickPost(item4.postLink)}
                    itemName='item4'
                    sectionId={sectionId}
                    href={item4.postLink}
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};