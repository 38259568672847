import React, {ChangeEvent, useEffect, useState} from 'react';
import '../../App.css';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {PostsListView} from "../posts/PostsListView";
import {BoxItem} from "../../shared/box/BoxItem";
import {GridOne} from "../../shared/components/templates/GridOne";
import {Box, Grid} from "@material-ui/core";
import {AppSettings, FilterType, PostType} from "../../shared/types";
import {TemplatesSlider} from "../../shared/components/slider/TemplatesSlider";
import {postListRelatedSelectedList, selectedListRelatedSelectedList} from "../../shared/utils";
import {GridTwo} from "../../shared/components/templates/GridTwo";
import {GridThree} from "../../shared/components/templates/GridThree";
import {GridFour} from "../../shared/components/templates/GridFour";
import {GridFive} from "../../shared/components/templates/GridFive";
import {GridSix} from "../../shared/components/templates/GridSix";
import {GridSeven} from "../../shared/components/templates/GridSeven";
import {fetchPostsList} from "../posts/posts-actions";
import {useSubscription} from "@cobuildlab/react-simple-state";
import {fetchPostsListEvent} from "../posts/posts-events";
import {OptionsView} from "../../shared/components/options/OptionsView";
import {SearchPosts} from "../../shared/components/search/SearchPosts";
import {createSectionErrorEvent, createSectionEvent} from "./section-events";
import {createSection} from "./section-actions";
import moment from "moment";
import * as toast from '../../shared/components/toasts/Toast';
import {SectionName} from "./components/SectionName";
import {GridEight} from "../../shared/components/templates/GridEight";
import {GridNine1} from "../../shared/components/templates/GridNine1";
import {GridTen} from "../../shared/components/templates/GridTen";
import {GridEleven} from "../../shared/components/templates/GridEleven";



export const CreateSectionView: React.FC<AppSettings> = (
  {settings}
) => {
  const [filter,setFilter] = useState<FilterType>({search:''});
  const [postList, setPostList] = useState<Array<any>>([]);
  const [selectedGrid, setSelectedGrid] = useState<string>('grid-one');
  const [postItemsSelected, setPostItemsSelected] = useState<Array<PostType>>([]);
  const [postPublish, setPostPublish] = useState<any>(moment(settings.date).format(moment.HTML5_FMT.DATETIME_LOCAL));
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sectionName, setSectionName] = useState<string>('');

  useEffect(()=>{
    fetchPostsList(settings,filter, postItemsSelected);
  },[settings, filter, postItemsSelected]);

  useSubscription(fetchPostsListEvent,(state)=>{
    if(state && state?.data){
      setPostList(state?.data);
    }
  },undefined
  );

  useSubscription(
    createSectionEvent,(state)=>{
      if(state && state.data && state.data.success){
        toast.success('SUCCESS',state.data.message );
        setTimeout(() => {
          window.location.href = `${settings.homeUrl}/wp-admin/admin.php?page=cover-page-menu-principal`;
        }, 1000);
      }
    },undefined);

  
  useSubscription(
    createSectionErrorEvent,
    (state)=>{
      if(state){
        toast.error('Error');
      }
    }, undefined
  );

  const onChange = (item: PostType, position: number) => {
    const {newPostList, newSelectedList} = postListRelatedSelectedList(postList, postItemsSelected, item, position);
    setPostList([...newPostList]);
    setPostItemsSelected([...newSelectedList]);
  };

  const onChangeFilter = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>{
    const {name, value} = event.target;
    setFilter({...filter, [name]: value});
  };

  const onChangeGrid = (item: PostType, name: string, position?: number) => {
    if(name === 'post-list'){
      const list = postItemsSelected.filter((post)=>{return post.position !== item.position;});
      setPostList([...postList, {...item, position: 0}]);
      setPostItemsSelected([...list]);
    }else if(name === selectedGrid && position){
      const {newSelectedList}=selectedListRelatedSelectedList(postItemsSelected, item,position);
      setPostItemsSelected([...newSelectedList]);
    }
  };

  const onClickSelected = (selected: string) =>{
    setSelectedGrid(selected);
  };

  const renderPostItems = postList.map((item, postIndex) => {
    // @ts-ignore
    const newItem = {...item, postIndex};
    return (<BoxItem key={postIndex} height={132} item={newItem} onChange={onChange}/>);
  });


  const onYesCreateSection = (): void =>{
    createSection(settings, selectedGrid, postItemsSelected, postPublish, sectionName);
    setIsLoading(true);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box p={2} width='100%' >
        <Grid container spacing={3} >
          <Grid item md={3}>
            <SearchPosts search={filter.search || ''} onChange={onChangeFilter} />
            <PostsListView>
              {renderPostItems}
            </PostsListView>
          </Grid>
          <Grid item md={9}>
            <SectionName name={sectionName} onChange={
              (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
                const {value} = event.target;
                setSectionName(value);
              }}
            />
            <TemplatesSlider selected={selectedGrid} onClick={onClickSelected} />
            <OptionsView
              setPostPublish={setPostPublish}
              postPublish={postPublish}
              onYes={onYesCreateSection}
              open={open}
              setOpen={setOpen}
              isLoading={isLoading}
            />
            {
              selectedGrid === 'grid-one'? (
                <GridOne items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>
              ): null
            }
            {
              selectedGrid === 'grid-two'?(
                (<GridTwo items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-three'?(
                (<GridThree items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-four'?(
                (<GridFour items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-five'?(
                (<GridFive items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-six'?(
                (<GridSix items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-seven'?(
                (<GridSeven items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-eight'?(
                (<GridEight items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-nine'?(
                (<GridNine1 items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-ten'?(
                (<GridTen items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }
            {
              selectedGrid === 'grid-eleven'?(
                (<GridEleven items={postItemsSelected} onChangeGrid={onChangeGrid} name={selectedGrid}/>)
              ): null
            }

          </Grid>
        </Grid>
      </Box>
    </DndProvider>
  );
};


