import React from "react";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {FrontGridType} from "../../../types";
import {FrontContainerBox} from "./components/FrontContainerBox";
import {FrontBoxItem} from "./components/FrontBoxItem";
import {onClickPost} from "../../../utils";
import {useTheme} from "@material-ui/core/styles";

export const FrontGridEleven: React.FC<FrontGridType> = ({
  items, sectionId, random = false
}) => {
  let item1 = items.find((item)=>{return item.position === 1;});
  let item2 = items.find((item)=>{return item.position === 2;});
  let item3 = items.find((item)=>{return item.position === 3;});

  if (random){
    const list = [1,2,3];
    const sortList:number[] = list.sort(()=> {
      return Math.random() - 0.5;
    });

    item1 = items.find((item)=>{return item.position === sortList[0];});
    item2 = items.find((item)=>{return item.position === sortList[1];});
    item3 = items.find((item)=>{return item.position === sortList[2];});
  }

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('isSmall', isSmall);

  if(isSmall){
    return(
      <>
        <Box width='100%'>
          <FrontContainerBox>
            {
              item1 ?(
                <FrontBoxItem
                  item={item1}
                  paddingPaper={false}
                  centerTitle
                  // @ts-ignore
                  onClick={()=>onClickPost(item1.postLink)}
                  itemName='item1'
                  sectionId={sectionId}
                  href={item1.postLink}

                />
              ):null
            }
          </FrontContainerBox>
        </Box>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <FrontContainerBox>
              {
                item2 ?(
                  <FrontBoxItem
                    item={item2}
                    paddingPaper={false}
                    centerTitle
                    itemName='item2'
                    sectionId={sectionId}
                    href={item2.postLink}
                    horizontal
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
          <Grid item sm={12}>
            <FrontContainerBox>
              {
                item3 ?(
                  <FrontBoxItem
                    item={item3}
                    paddingPaper={false}
                    centerTitle
                    itemName='item3'
                    sectionId={sectionId}
                    href={item3.postLink}
                    horizontal
                  />
                ):null
              }
            </FrontContainerBox>
          </Grid>
        </Grid>
      </>
    );
  }



  return (
    <Grid container  spacing={1} >
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item1 ?(
              <FrontBoxItem
                item={item1}
                paddingPaper={false}
                // @ts-ignore
                onClick={()=>onClickPost(item1.postLink)}
                itemName='item1'
                sectionId={sectionId}
                href={item1.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item2 ?(
              <FrontBoxItem
                item={item2}
                paddingPaper={false}
                // @ts-ignore
                onClick={()=>onClickPost(item2.postLink)}
                itemName='item2'
                sectionId={sectionId}
                href={item2.postLink}

              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
      <Grid item md={4}>
        <FrontContainerBox>
          {
            item3 ?(
              <FrontBoxItem
                item={item3}
                paddingPaper={false}
                // @ts-ignore
                onClick={()=>onClickPost(item3.postLink)}
                itemName='item3'
                sectionId={sectionId}
                href={item3.postLink}
              />
            ):null
          }
        </FrontContainerBox>
      </Grid>
    </Grid>
  );
};